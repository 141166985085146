import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const theme = extendTheme({
  config: {
    initialColorMode: "dark",
    useSystemColorMode: false,
  },
  colors: {
    // black: "#010001",
  },
  styles: {
    global: (props) => ({
      body: {
        bg: "black",
        color: "white",
      },
    }),
  },
});

export default theme;
