/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import Header from "./header";
import { useStaticQuery, graphql } from "gatsby";
import { Box, Link } from "@chakra-ui/react";
import theme from "../theme";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <ChakraProvider resetCSS theme={theme}>
      {/* <Header siteTitle={data.site.siteMetadata?.title || `DEATHGIRL`} /> */}

      <Box backgroundColor="black" as="main">
        {children}
      </Box>
    </ChakraProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
